.dashboard-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .create-bot-btn {
    display: inline-block;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    width: 200px;
    text-align: center;
  }
  
  .bots-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .bot-card {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: left;
  }
  
  .bot-card img.bot-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .bot-card h2 {
    margin-top: 0;
  }
  
  .bot-card .custom-links {
    margin-top: 10px;
  }
  
  .bot-card .custom-links div {
    margin-bottom: 5px;
  }
  
  .bot-actions {
    display: flex;
    align-items: center;
  }
  
  .edit-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    height: 40px;
  }
  
  .delete-btn {
    width: 40px;
    height: 40px;
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  