.landing-container {
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .landing-header {
    margin-bottom: 40px;
  }
  
  .landing-header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .landing-header p {
    font-size: 1.2em;
    color: #555;
  }
  
  .features {
    margin-bottom: 40px;
  }
  
  .features h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .features ul {
    list-style-type: none;
    padding: 0;
  }
  
  .features li {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .signup-btn, .login-btn {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 200px;
  }
  
  .signup-btn {
    background-color: #28a745;
    color: white;
  }
  
  .login-btn {
    background-color: #007bff;
    color: white;
  }
  