body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.dashboard {
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

form div {
  margin-bottom: 15px;
}

input[type="text"], input[type="email"], input[type="password"], input[type="color"], select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #218838;
}

.bot-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.bot {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
}

.bot img {
  max-width: 100%;
  border-radius: 50%;
}

.bot h2 {
  color: #333;
}

.bot p {
  color: #666;
}

.bot a {
  color: #007bff;
  text-decoration: none;
}

.bot a:hover {
  text-decoration: underline;
}

.custom-links a {
  display: block;
  margin-top: 5px;
  color: #007bff;
}

.bot button {
  background-color: #dc3545;
}

.bot button:hover {
  background-color: #c82333;
}

.link-button {
  background-color: #007bff;
  color: #fff;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
}

.link-button:hover {
  background-color: #0056b3;
}
