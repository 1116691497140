.container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .container h1 {
    text-align: center;
    color: #333;
  }
  
  form .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  form .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  form .form-group input[type="text"],
  form .form-group input[type="color"],
  form .form-group select,
  form .form-group textarea,
  form .form-group input[type="file"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  form .form-group textarea {
    resize: vertical;
  }
  
  .custom-link-group {
    margin-bottom: 20px;
  }
  
  button[type="button"].add-link-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button[type="button"].add-link-btn:hover {
    background-color: #0056b3;
  }
  
  button[type="button"].remove-link-btn {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
  
  button[type="button"].remove-link-btn:hover {
    background-color: #c82333;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button[type="submit"]:hover {
    background-color: #218838;
  }
  
  .form-group.add-remove-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  