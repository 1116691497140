.bot-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
  }
  
  .bot-content {
    max-width: 600px;
  }
  
  .bot-content h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
  }
  
  .bot-content p {
    font-size: 1.2em;
    margin-bottom: 1em;
  }
  
  .bot-button, .custom-link {
    display: block;
    margin: 10px 0;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .custom-links {
    margin-top: 20px;
  }
  